// ! ---------- LAYOUT

body {
    overflow: hidden; }

.page-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none; }

.page,
.page-overlay {
    pointer-events: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    @include page-margin-property(top);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: $z-index-content;
    transition: transition(transform opacity left box-shadow, $factor: 1.5);
    @extend %disable-transition-while-resizing;

    @include layout--pages-standalone {

        .is-nav-visible & {
            transform: translateY(80vh);
            transform: translateY(var(--header-height)); }

        .is-contact-visible &,
        .is-imprint-visible &,
        .is-login-visible &, {
            transform: translateY(93%) !important; } }

    @include layout--pages-as-stack {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        @include page-margin-property(left);

        .is-contact-visible &,
        .is-imprint-visible &,
        .is-login-visible &, {
            transform: translateY(-110%) !important; } } }

.page + .page {
    &:not(.page--template-home):last-child,
    &:not(.page--template-home).is-moving-to, {
        @include layout--pages-as-stack {
            @include page-margin-property(left, $factor: 2); } } }

.page {
    &.is-new, {
        @include layout--pages-as-stack {
            transform: translateX(var(--page-margin));
            opacity: 0;
            visibility: visible;
            transition: none; } }

    &.is-removing {
        @include layout--pages-as-stack {
            pointer-events: none;
            transform: translateX(var(--page-margin));
            opacity: 0; } }

    &:last-child,
    &:nth-last-child(2),
    &.is-moving-to-next, {
        box-shadow: .1em .25em 1em .25em rgba(palette('text'), 0.3); }

    .template-home &:nth-last-child(2) {
        opacity: 0;
        pointer-events: none; }

    &:nth-last-child(2), {
        .page__content {
            pointer-events: none; }
        .page__trigger {
            display: block; }
        .template-home & .page__trigger {
            display: none; } }

    &:nth-last-child(3):not(.is-moving-to-next), {
        box-shadow: none;
        opacity: 0; }

    &:nth-last-child(4),
    &:nth-last-child(5),
    &:nth-last-child(6), {
        display: none; }

    &:nth-last-child(3),
    &:nth-last-child(4),
    &:nth-last-child(5),
    &:nth-last-child(6), {
        pointer-events: none; } }

.page__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(green, 0.2)
    display: none;
    cursor: pointer;
    z-index: $z-index-content + 1; }


.page-overlay {
    z-index: $z-index-content + 2;
    pointer-events: none;
    cursor: pointer;
    @include layout--pages-standalone {
        .is-nav-visible & {
            pointer-events: auto; } } }

.page {
    background: palette('background'); }

.page--template-home {

    @include layout--pages-as-stack {
        @include page-margin-property(bottom, $factor: 2/3);

        .is-secondary-nav-visible & {
            transform: translateY(-3rem); } }

    .is-slideshow-info-visible &, {
        @include layout--pages-as-stack {
            @include logo-width-transform; } } }

.page--template-home,
.page--template-project-list,
.page--template-student-list,
.page--template-project,
.page--template-work-list {
    overflow: hidden; }

.safari .page--template-project {
    overflow: visible; }

.page--template-project,
.page--template-work-list {
    background: palette('text');
    color: palette('background'); }




// ! ---------- HEADER

@mixin header-padding-standalone {
    @include page-margin-property(padding-top);
    padding-left: 2em;
    padding-right: 2em;
    @include breakpoint(small) {
        padding-left: 5em;
        padding-right: 5em; } }

.header {

    @include layout--pages-standalone {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        z-index: $z-index-header;
        @include header-padding-standalone;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .header__logo {
            margin-bottom: $one-line-height; }

        .header__logo,
        .header__main-navigation,
        .header__secondary-navigation, {
            flex: 1 0 100%; }

        @include breakpoint(640) {
            .header__logo,
            .header__main-navigation, {
                flex: 0 0 auto; } } }


    @include layout--pages-as-stack {
        @include page-margin-property(padding, $factor: 1/3); // same as .page .logo

        .header__logo {
            position: relative;
            z-index: $z-index-header + 1; } } }


.header__main-navigation,
.header__secondary-navigation,
.header__logo, {
    transition: transition(opacity);
    @extend %disable-transition-while-resizing;
    @include layout--pages-standalone {
        .is-contact-visible &,
        .is-imprint-visible &,
        .is-login-visible &, {
            opacity: 0;
            pointer-events: none; } } }

.header__main-navigation {

    @include font-scale(xl, xl, l);

    @include layout--pages-as-stack {
        position: absolute;
        top: 0;
        right: 100%;
        width: 100vh;
        transform: rotate(-90deg);
        transform-origin: 100% 0%;
        z-index: $z-index-header;

        ul {
            @include page-margin-property(height);
            @include page-margin-property(padding-left, $factor: 1.75);
            @include page-margin-property(padding-right, $factor: 3);
            @media (min-height: 40em) {
                @include page-margin-property(padding-left, $factor: 4);
                @include page-margin-property(padding-right, $factor: 4); }
            li {
                padding: 0 .5em;
                text-align: center;
                &:first-child {
                    text-align: left; }
                &:last-child {
                    text-align: right; } } } }

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-basis: auto;

        @include layout--pages-standalone {
            flex-wrap: wrap; }

        @include layout--pages-as-stack {
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }

        li {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            transition: all .5s;
            @extend %disable-transition-while-resizing;
            flex-grow: 0.001;
            &.is-current,
            &:hover {
                a {
                    @extend %underline-style; } }
            &.is-current, {
                flex-grow: 1; } } } }


.header__secondary-navigation {
    @include layout--pages-standalone {
        padding-top: $one-line-height * 3;
        padding-bottom: $one-line-height; }

    @include layout--pages-as-stack {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: $z-index-footer + 1;
        @include page-margin-property(padding-bottom, $factor: 1/3); }

    width: 100%;
    text-align: center;
    @extend %accent-typography;

    form, ul {
        display: inline-block;
        @include layout--pages-standalone {
            padding-bottom: $one-line-height * 1/2; } }

    label, input {
        display: inline; }

    input {
        width: 10em;
        @include layout--pages-as-stack {
            width: 12em; } }

    form {
        flex: 0 0 auto;
        padding-left: 2em;
        padding-right: 2em;
        @include layout--pages-as-stack {
            padding-left: 0; }

        display: none; }

    ul {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        @include breakpoint(medium) {
            flex-grow: 0; } }

    li {
        display: inline-block;
        padding: 0 .75em;
        cursor: pointer;
        &:hover {
            text-decoration: underline; }
        &:first-child {
            padding-left: 0; }
        &:last-child {
            padding-right: 0; } }

    .is-contact-visible & .js-contact-toggle,
    .is-imprint-visible & .js-imprint-toggle, {
        text-decoration: underline; } }

.header__secondary-navigation-inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; }

.header__navigation-toggle,
.header__navigation-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $z-index-nav-toggle;
    @extend %neutral-button;
    @extend %no-tap-highlight;
    @include font-scale(xl, xxxl);
    line-height: 1;
    padding: .15em .4em;

    @include layout--pages-as-stack {
        display: none; } }

.header__navigation-toggle {
    .is-nav-visible & {
        visibility: hidden; } }

.header__navigation-close {
    span {
        @extend %hide-visually; }
    &::after {
        display: inline-block;
        content: '✕';
        font-weight: bold;
        transform: translateY(.1em) rotate(90deg); }
    visibility: hidden;
    .is-nav-visible & {
        visibility: visible; } }


// ! ---------- CONTENT


.page__content {}



// ! ---------- FOOTER


.info-footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //width: 100%
    //height: 100%

    @include layout--pages-standalone {
        @include header-padding-standalone;
        padding-bottom: 3 * $one-line-height; }

    @include layout--pages-as-stack {
        @include page-margin-property(left);
        @include page-margin-property(right);
        padding-top: 6 * $one-line-height;
        padding-bottom: 6 * $one-line-height; }

    .is-imprint-visible &,
    .is-contact-visible &, {
        z-index: $z-index-footer; } }

.info-footer__imprint-wrapper,
.info-footer__contact-wrapper, {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @extend %scrollable;

    > div {
        margin: auto; } }

.info-footer__imprint-wrapper {
    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);
    .is-imprint-visible & {
        pointer-events: auto;
        opacity: 1; } }

.info-footer__imprint {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1600px;
    @include font-scale(m, m_l);

    @include breakpoint(large) {
        @include page-margin-property(padding-left, $factor: 2);
        @include page-margin-property(padding-right, $factor: 2); } }

.info-footer__imprint-title {
    flex: 0 0 100%;
    padding-left: 1em;
    margin-bottom: 1em;
    @include font-scale(xxxl); }

.info-footer__imprint-main {
    flex: 0 0 100%;
    margin-bottom: 4 * $one-line-height;
    @include breakpoint(large) {
        flex-basis: 33%;
        @include page-margin-property(padding-right, $factor: 1); }

    h1, h2, h3, h4, h5, h6 {
        @include font-scale(xl); } }



.info-footer__imprint-secondary {
    flex: 0 0 100%;
    @include breakpoint(large) {
        flex-basis: 67%;
        @include page-margin-property(padding-left, $factor: 1);
        @include page-margin-property(padding-right, $factor: 1); }
    @include breakpoint(xlarge) {
        flex-basis: 33%;
        @include page-margin-property(padding-right, $factor: 0); } }

.info-footer__contact-wrapper {
    max-width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);
    .is-contact-visible & {
        pointer-events: auto;
        opacity: 1; } }

.info-footer__contact {
    @include font-scale(m, xl);

    h1, h2, h3, h4, h5, h6 {
        @include font-scale(xl, xxxl); }

    blockquote {
        font-size: 1em !important; } }




// ! ---------- SLIDESHOW REMOTE CAPTION

.slideshow-info {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: $z-index-footer + 1;
    @include page-margin-property(bottom, $factor: 2/3);

    pointer-events: none;
    > * {
        pointer-events: auto; }

    @include layout--pages-standalone {
        display: none; } }

.slideshow-info__toggle {
    @extend %neutral-button;
    position: absolute;
    left: 0;
    bottom: 0;
    @include page-margin-property(width);
    @include page-margin-property(height);
    @extend %accent-typography;
    text-align: center;
    transition: transition(opacity);
    opacity: 0;
    pointer-events: none;
    .template-home .slideshow-info__text:not(:empty) + & {
        opacity: 1;
        pointer-events: auto; }

    &::after {
        display: inline-block;
        content: '↑';
        transform: rotate(90deg) scale(1.25);
        transition: transition(transform);
        .is-slideshow-info-visible & {
            transform: rotate(-90deg) scale(1.25); } } }

.slideshow-info__text {
    @extend %logo-width;
    @include page-margin-property(margin-left, $factor: 4/3);
    @include page-margin-property(padding-right, $factor: 3/3);
    @include page-margin-property(margin-bottom, $factor: 1/3);
    @include font-scale(s);
    transition: transition(opacity);
    opacity: 0;
    pointer-events: none;
    .template-home.is-slideshow-info-visible & {
        opacity: 1;
        pointer-events: auto; } }
