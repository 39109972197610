// ! ---------- TEXT BLOCKS


// These apply wherever users enter rich-text content themselves

.text-block {

    a, a:hover {}


    h1, h2, h3, h4, h5, h6 {
        margin-bottom: $one-line-height--absolute; }

    h1, h2 {
 }        // font-size: 1.8em

    p, ul, ol, figure, img, table, blockquote, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height;
        &:last-child {
            margin-bottom: 0; } }

    figure img {
        margin-bottom: 0; }

    var {
        @extend %accent-typography; }

    blockquote {
        @extend %accent-typography;
        padding-left: 1.6em;
        margin-top: -$one-line-height;
        @include font-scale(m, s); }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .5em;
                content: "•"; } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .4em;
                content: counter(table-ol) "."; } } } }

.text-block--no-headers {
    h1, h2, h3, h4, h5, h6 {
 } }        // font-size: 1em
