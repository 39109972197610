// ! ---------- BREAKPOINT PROPERTY: PAGE MARGIN / PAGE STACK DISTANCE

@mixin page-margin-property($property, $factor: 1) {
    @include breakpoint-map-property($property, $page-margin, $factor); }

@mixin page-padding-property($property, $factor: 1) {
    @include breakpoint-map-property($property, $page-padding, $factor); }

@mixin text-indent-property($property, $factor: 1) {
    @include breakpoint-map-property($property, $text-indent, $factor); }

@mixin text-indent-padding($factor: 1) {
    @include text-indent-property(padding-left, $factor); }




// ! ---------- NAMED BREAKPOINTS / LAYOUTS

@mixin layout--nav-always-visible {
    @include breakpoint(large) {
        @content; } }

@mixin layout--nav-as-overlay {
    @include breakpoint(medium down) {
        @content; } }


@mixin layout--pages-standalone {
    @include layout--nav-as-overlay {
        @content; } }

@mixin layout--pages-as-stack {
    @include layout--nav-always-visible {
        @content; } }


@mixin layout--students-side-by-side {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--work-list-horizontal {
    @include breakpoint(landscape) {
        @content; } }





// ! ---------- MIXINS / HELPERS

%bold {
    font-weight: bold;
    font-weight: 500; // Aktiv Grotesk Medium
    font-weight: 700; } // Aktiv Grotesk Bold

%list--icon-dash {
    display: table;
    list-style: none;

    > li {
        display: table-row;

        &::before {
            display: table-cell;
            padding-right: .5em;
            content: "—"; } } }

%link--icon-arrow-se {
    &::before {
        content: '↘ '; } }

%links-within--icon-arrow-se {
    a {
        @extend %link--icon-arrow-se; } }

%page-logo-padding--top {
    @include page-margin-property(padding-top, $factor: 1/3); } // same as .header

%page-logo-padding {
    @include page-margin-property(padding-top, $factor: 1/3); // same as .header
    @include page-margin-property(padding-left);
    @include page-margin-property(padding-right); }

@mixin logo-width {
    width: $logo-width;

    @each $bp, $f in $fontsize-factor {
        @include breakpoint($bp) {
            width: $logo-width * $f; } } }

@mixin logo-width-transform {
    transform: translateX(#{$logo-width});
    @each $bp, $f in $fontsize-factor {
        @include breakpoint($bp) {
            transform: translateX(#{$f * $logo-width}); } } }

%logo-width {
    @include logo-width; }


%photo-overlay-element {
    @supports(mix-blend-mode: difference) {
        mix-blend-mode: difference;
        color: white;
        filter: drop-shadow(0 0 .1em rgba(55, 55, 55, .3)); } }

%undo-photo-overlay-element {
    mix-blend-mode: normal;
    filter: none; }

%photo-overlay-element--shadow {
    @supports(mix-blend-mode: difference) {
        mix-blend-mode: difference;
        color: white;
        filter: drop-shadow(0 0 .1em rgba(55, 55, 55, .3)); } }
