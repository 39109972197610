// ! ---------- PAGE





// ! ---------- NAVIGATION


.navigation {
    ul {
        list-style: none; }
    li {
        display: inline-block;
        margin-right: 1em; } }



// ! ---------- LOGO


.logo {
    display: inline-block;
    position: relative;
    @include font-scale(xxl);
    @extend %logo-width;
    letter-spacing: 0;
    line-height: 0.94;
    .header__logo & {
        @include font-scale(xxxxl, xxxxl, xxl); } }

.logo__main,
.logo__name {
    &::after {
        display: inline-block;
        white-space: pre;
        content: "\A"; } }

.logo__name {
    position: absolute;
    left: 8.0em;
    top: .1em;
    font-size: .415em;
    letter-spacing: 0.08em;
    line-height: 1; }

// Logo on page

.page-logo {
    @extend %page-logo-padding; }

.team__logo {
    @include breakpoint(medium down) {
        @include hide-visually; } }

.project__logo {
    @include breakpoint(small down) {
 } }        // @include hide-visually

.project-navigation__logo {
    @include layout--pages-standalone {
        padding-left: 0 !important; }
    @include breakpoint(small down) {
        @include hide-visually; } }

.work-list__logo {
    display: none;
    @include layout--work-list-horizontal {
        display: block; } }


// ! ---------- FORMS


input {
    appearance: none;
    border: 0;
    color: inherit;
    background: transparent;
    font: inherit;
    line-height: normal;
    text-decoration: none;
    text-align: left;
    outline: none; }

input[type=text],
input[type=search], {
    border-bottom: 1px solid; }




// ! ---------- SLIDER


.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: palette('background');

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.slider__inner {
    height: 100%; }

.slider__slide {
    height: 100%; }

.slider__pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: .5em .3em;
    @extend %accent-typography;
    @include font-scale(m, l);
    @extend %photo-overlay-element;

    @include breakpoint(small) {
        padding: .5em; }
    @include layout--pages-as-stack {
        padding-right: 1em; }

    .pagination-bullet {
        display: inline-block;
        border: 1px solid;
        border-radius: 50%;
        padding: .2em;
        width: 1.5em;
        height: 1.5em;
        line-height: 1em;
        margin-right: .25em;
        text-align: center;
        opacity: .25;
        transform: scale(.8);
        cursor: pointer;
        &.is-active,
        &:hover {
            opacity: 1; } } }

.slider__slide {
    position: relative; }

.slider__slide--text {
    background: palette('text');
    display: flex;
    justify-content: center;
    align-items: center;
    &.text-brightness--dark {
        color: black; }
    &.text-brightness--light {
        color: white; } }

.slider__slide--text.has-background,
.slider__slide--image {

    .slider__text,
    .slider__caption {
        @extend %photo-overlay-element; }

    &.text-brightness--dark {
        .slider__text,
        .slider__caption {
            @extend %undo-photo-overlay-element;
            color: black; } }

    &.text-brightness--light {
        .slider__text,
        .slider__caption {
            @extend %undo-photo-overlay-element;
            color: white; } } }

.slider__text {
    position: relative;
    text-align: center;
    padding: $one-line-height--relative;
    line-height: $lineheight-xxs;
    letter-spacing: 0;
    @include fontsize(l);
    @include breakpoint(small) {
        font-size: 6vw; } }

.slider__caption {
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: rotate(90deg);
    transform-origin: 0% 100%;
    width: 80vh;
    z-index: 3;
    @extend %accent-typography;
    padding: .5em;
    padding-left: 1em;
    @include font-scale(m, l);
    @include layout--pages-as-stack {
        padding-left: 3em;
        bottom: 0;
        transform: none;
        width: auto;
        max-width: 75%; }

    span {
        cursor: pointer; } }

.slider__remote-caption {
    @extend %hide-visually; }

.project-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.project-list__navigation {
    flex: 0 0 auto;
    margin-bottom: $one-line-height; }

.project-list__list {
    flex: 1 1 auto;

    display: flex;
    flex-direction: row;

    &::after {
        content: '';
        flex: 0 0 var(--page-margin); }

    @extend %scrollable--horizontal; }

.project-list__column {
    flex: 0 0 100vw;
    position: relative;
    flex-basis: calc(100vw - var(--page-margin));

    @include breakpoint(640) {
        flex-basis: 50vw;
        flex-basis: calc(50vw - (var(--page-margin) / 2));
        max-width: 560px; }
    @include layout--pages-as-stack; }


.project-list__column-title {
    position: absolute;
    top: 0;
    right: 100%;
    transform: rotate(-90deg) translateY(-100%) translateY( calc(var(--grid-gutter) / 2) );
    transform: rotate(-90deg) translateY(-100%) translateY(var(--page-margin));
    transform-origin: 100% 0%;
    @include font-scale(xxl);
    line-height: .9;
    user-select: none;
    .project-list__column:first-child &,
    .project-list__column.is-first-visible &, {
        transform: rotate(-90deg) translateY(-100%) translateY(var(--page-margin)); } }

.project-list__column-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend %scrollable;

    @if false {
        padding-left: calc(var(--grid-gutter) / 2);
        padding-right: calc(var(--grid-gutter) / 2); }
    @else {

        padding-left: var(--page-margin); }
    @include layout--pages-as-stack;

    .project-list__column:first-child &,
    .project-list__column.is-first-visible &, {
        padding-left: var(--page-margin); }

    &::before {
        display: block;
        content: '';
        height: 2.4em;
        @include fontsize(xxl); } }

.project-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;

    padding-left: var(--page-margin);
    @include layout--pages-as-stack {
        padding-left: 0; }

    @include page-margin-property(padding-right);

    ul {
        flex: 0 1 auto;
        display: flex;
        flex-wrap: wrap;
        @include page-padding-property(padding-top);
        @include layout--pages-as-stack {
            @include page-margin-property(padding-top); } }

    li {
        flex: 0 1 100%;
        @include page-margin-property(padding-right);
        @include breakpoint(small) {
            flex-basis: 50%; }
        @include breakpoint(xlarge) {
            flex-basis: auto; }

        a {
            flex: 0 0 auto;
            &:hover,
            &.is-active {
                text-decoration: underline; } } } }

.project-navigation__link {
    flex: 0 0 50%;
    letter-spacing: $letterspacing-xxl;
    text-transform: uppercase;
    text-align: right;
    transform: scale(1.05);
    transform-origin: 50% 0%;
    @include page-padding-property(margin-top);
    @include layout--pages-as-stack {
        @include page-margin-property(margin-top); }

    @include breakpoint(small) {
        flex: 0 1 auto; } }

.project-navigation__arrows {
    flex: 0 1 auto;
    @include page-padding-property(margin-top);
    @include layout--pages-as-stack {
        @include page-margin-property(margin-top); }
    min-width: 4em;
    text-align: right;
    margin-left: 1em;

    @include breakpoint(medium) {
        min-width: 5em;
        margin-left: 2em; }

    @include breakpoint(all-plus down) {
        position: absolute;
        top: 2.5 * $one-line-height;
        right: var(--page-margin); }

    @include font-scale(l);

    button {
        @extend %neutral-button;
        text-align: center;
        transition: transition(opacity);
        opacity: 0;
        pointer-events: none;
        padding: 0 .5em;
        margin-top: -.4em;
        font-size: 1.5em;
        &.is-enabled {
            opacity: 1;
            pointer-events: auto; }

        &::after {
            display: inline-block;
            content: '↑';
            transform: rotate(-90deg); }

        &:last-child {
            padding-right: 0;
            &::after {
                transform: rotate(-90deg) scaleY(-1); } } } }

.project-navigation__categories {
    @extend %accent-typography;
    @include font-scale(m, m, m_l); }

.project-preview {
    margin-bottom: $one-line-height * 2;
    @include breakpoint(medium) {
        margin-bottom: $one-line-height * 3; }
    @include breakpoint(xlarge) {
        margin-bottom: $one-line-height * 4; }

    img {
        width: 100%;
        height: auto; } }


.project-preview__category {
    @extend %accent-typography;
    padding-bottom: .5em;
    @include font-scale(s, s, m); }

.project-preview__title {
    @include font-scale(l);
    padding-top: .5em; }

.project-preview__summary {
    letter-spacing: 0; }

.project-preview__more {
    @extend %accent-typography; }

.project-preview__info {
    @extend %accent-typography;
    margin: $one-line-height 0;
    padding-left: 2em;
    letter-spacing: 0;

    &::before {
        content: '                   ';
        letter-spacing: -.3em;
        text-decoration: underline; } }





.project {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 200%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;

    @include breakpoint(large down) {
        transition: transition(transform, $factor: 2);
        .is-project-media-visible & {
            transform: translateX(-50%); }
        .is-project-media-flashing & {
            transform: translateX(-32.5%); } }

    @include layout--pages-as-stack {
        width: 100%; } }



.project__navigation {
    position: relative;
    @include layout--pages-standalone {
        @include page-margin-property(padding-top); } }

.project__media-toggle {
    @extend %neutral-button;
    position: absolute;
    bottom: 0;
    right: 0;
    @include page-margin-property(right);
    @include layout--pages-as-stack {
        display: none; }
    &::after {
        display: inline-block;
        content: '→';
        padding-left: .5em; } }

.project__text-toggle {
    @extend %neutral-button;
    position: absolute;
    top: 0;
    left: 0;
    @include page-margin-property(left);
    @include page-margin-property(top);
    @include layout--pages-as-stack {
        display: none; }
    &::before {
        display: inline-block;
        content: '←';
        padding-right: .5em; } }

.project__page-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    @include font-scale(l_xl, l_xl, xxl);
    text-transform: uppercase;
    line-height: 1;
    pointer-events: none;
    transform: translateY(-.275em);
    padding: 0 1em;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include layout--pages-as-stack {
        width: auto;
        min-width: 50vh;
        max-width: 80vh;
        top: 100%;
        transform: translateX(-.275em) rotate(-90deg);
        transform-origin: 0% 0%; } }

.project__main,
.project__media {
    flex: 0 0 50%;
    @extend %scrollable;
    height: 100%; }

.project__main {
    display: flex;
    flex-direction: column; }

.project__info {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include page-margin-property(padding-left);
    @include page-margin-property(padding-right);
    padding-top: $one-line-height * 2;
    @include breakpoint(medium) {
        padding-top: $one-line-height * 4; }

    .break {
        flex: 0 0 100%;
        visibility: hidden;
        height: 1px;
        overflow: hidden; }

    .project__data-point {
        flex: 0 0 100%;
        @extend %accent-typography;
        @include font-scale(s);
        padding-bottom: $one-line-height;
        justify-self: flex-start;
        max-width: 21em;
        @include breakpoint(small) {
            flex-basis: percentage(1/3);
            padding-right: 3em;
            max-width: none; } } }

.project__info__main {
    @include breakpoint(small) {
        flex-basis: percentage(2/3); } }

.project__info__main,
.project__poster {
    margin-top: $one-line-height * 2;
    @include breakpoint(small) {
        margin-top: $one-line-height * 3; } }

.project__poster {
    flex-basis: percentage(1/2);
    margin-right: auto;
    @include breakpoint(small) {
        flex-basis: percentage(1/3); }
    padding-right: .7rem;
    padding-bottom: .7rem;
    margin-bottom: $one-line-height * 2;
    padding-top: .4em;

    span {
        display: block;
        @extend %accent-typography;
        @include font-scale(s);
        margin-bottom: $one-line-height / 2;

        &:empty {
            display: none; } }

    div {
        display: inline-block;
        position: relative;
        width: 100%;
        max-width: 250px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid;
            z-index: -1;
            transform: translate(.6rem, .6rem); } }
    img {
        display: block;
        width: 100%; } }

.project__info__title {
    @include font-scale(l);
    text-transform: uppercase; }

.project__info__subheadline {
    @extend %accent-typography;
    @include text-indent-padding; }

.project__text,
.project__additional-text {
    .text-block {
        max-width: 32em;
        a {
            @extend %link--icon-arrow-se;
            @extend %accent-typography; } } }

.project__text {
    margin-top: $one-line-height;
    padding-right: 3em;
    margin-bottom: $one-line-height * 2; }

.project__additional-text {
    flex-basis: percentage(1);
    // margin-right: auto
    @include breakpoint(small) {
        flex-basis: percentage(2/3);
 }        // margin-right: 0
    padding-bottom: $one-line-height * 2;
    @extend %accent-typography;
    @include font-scale(s); }


.project__media {
    @include page-margin-property(padding);
    position: relative;
    @include layout--pages-standalone {
        padding-top: 5 * $one-line-height !important; }

    &::after {
        display: block;
        content: '';
        height: 5 * $one-line-height !important; } }

.project-media {

    --caption-offset: 0px;

    &.has-caption {
        --caption-offset: #{-5 * $one-line-height}; }

    figure {
        margin: 0 auto;
        position: relative;
        &.portrait {
            width: percentage(2/3); } }

    .ratio-container,
    .TextformatterVideoEmbed {
        position: relative;
        z-index: 1;
        transition: transition(transform, $factor: 1.5); }

    img {
        width: 100%; }

    figcaption {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: transition(opacity, $factor: 1);
        @include font-scale(s);
        padding-top: .5em; }

    &.is-caption-visible {
        figcaption {
            opacity: 1; }
        .ratio-container,
        .TextformatterVideoEmbed {
            transform: translateY(var(--caption-offset)); } } }

.project-media__info {
    @extend %accent-typography;
    display: block; }

.project-media + .project-media {
    @include page-margin-property(margin-top, $factor: 1.5); }

.js-project-media {
    cursor: pointer; }


.project-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: palette('background');
    color: palette('text');
    z-index: $z-index-lightbox;

    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);
    .is-lightbox-visible & {
        opacity: 1;
        pointer-events: auto; } }

.project-lightbox__slideshow {
    width: 100%;
    height: 100%;
    background: palette('background');

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

    iframe {
        width: 100%;
        height: 100%; } }


.project-lightbox__slide--portrait {
    @include breakpoint(landscape) {
        img {
            object-fit: contain; } } }

.project-lightbox__slide--landscape {
    @include breakpoint(portrait) {
        img {
            object-fit: contain; } } }

.project-lightbox__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5em;
    @include fontsize(xl);
    display: inline-block;
    cursor: pointer;
    z-index: 3;
    text-align: center;
    @extend %photo-overlay-element--shadow;

    &::after {
        content: '✕';
        width: 1em; } }


.project-lightbox__arrow-up,
.project-lightbox__arrow-down, {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    @include fontsize(xl);
    text-align: center;
    @extend %photo-overlay-element--shadow;

    &::after {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        right: .5em;
        content: '↑';
        width: 1em; } }

.project-lightbox__arrow-up {
    top: 0;
    &::after {
        top: .5em; } }

.project-lightbox__arrow-down {
    top: 50%;
    &::after {
        transform: rotate(180deg);
        bottom: .5em; } }




.work-list {}

.work-list__title {
    @extend %hide-visually; }

.work-list__logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }

.work-list__slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }

.work-slideshow__pagination {
    position: absolute;
    top: 0;
    left: 0;
    @extend %accent-typography;
    @include font-scale(l);
    @include page-padding-property(padding-left);
    padding-top: .3em;
    z-index: 3;
    width: auto;
    bottom: auto;
    right: auto;
    pointer-events: none;

    @include layout--work-list-horizontal {
        transform: translateX(-100%) translateY(#{5 * $one-line-height}) rotate(-90deg);
        transform-origin: 100% 0%; }


    span:first-child {
        display: inline-block;
        width: 3.5em;
        &::after {
            content: '/'; }

        @include breakpoint(small) {
            width: 6em;
            padding-left: 2em; } } }

.work-slideshow__slide {
    width: 100%;
    height: 100%;
    position: relative;

    figure {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        @include page-padding-property(top);
        @include page-padding-property(left);
        @include page-padding-property(right, $factor: 3);
        @include page-padding-property(bottom);

        @include layout--work-list-horizontal {
            flex-direction: row;
            align-items: flex-end;
            @include page-margin-property(top);
            @include page-margin-property(left);
            @include page-margin-property(right, $factor: 2);
            @include page-margin-property(bottom); } }

    figcaption {
        padding-top: 1 * $one-line-height;

        @include layout--work-list-horizontal {
            flex: 0 0 17em;
            order: 1;
            padding-right: 2em;
            padding-bottom: $one-line-height * 2; }

        &:first-child {
            visibility: hidden;
            @include layout--work-list-horizontal {
                display: none; } } }

    div {
        flex: 1 1 100%;
        height: 100%;
        width: 100%;
        position: relative;
        @include layout--work-list-horizontal {
            order: 2;
            width: auto; } }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include layout--work-list-horizontal {
            object-position: 100% 50%; } } }

.work-slideshow__caption,
.work-slideshow__info,
.work-slideshow__students {
    display: block; }

.work-slideshow__info {
    @extend %accent-typography;
    @include fontsize(s);
    @include text-indent-padding(); }

.work-slideshow__students {
    text-align: right;
    padding-top: $one-line-height;
    &:empty {
        display: none; } }


.work-slideshow__index-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5em;
    display: inline-block;
    cursor: pointer;
    z-index: 3;
    text-align: center;
    @include fontsize(xl);
    width: 1em;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        transform:  translate(-50%, -50%) rotate(-90deg);
        white-space: nowrap;
        @include font-scale(s, m); } }


.work-slideshow__arrow-up,
.work-slideshow__arrow-down, {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    @include fontsize(xl);
    text-align: center;
    outline: none;

    &::after {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        right: .5em;
        content: '↑';
        width: 1em; } }

.work-slideshow__arrow-up {
    top: 0;
    &::after {
        top: .65em; } }

.work-slideshow__arrow-down {
    top: 50%;
    &::after {
        transform: rotate(180deg);
        bottom: .65em; } }

.work-list__index {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: palette('text');
    @extend %scrollable;

    transition: transition(opacity);
    opacity: 0;
    pointer-events: none;
    .is-work-index-visible & {
        opacity: 1;
        pointer-events: auto; } }

.work-index {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @include page-padding-property(padding);
    @include page-padding-property(padding-right, $factor: 3);
    @include page-padding-property(padding-top, $factor: 2);

    @include layout--work-list-horizontal {
        @include page-margin-property(padding);
        @include page-margin-property(padding-right, $factor: 2);
        @include page-margin-property(padding-top, $factor: 4); } }


.work-index__close {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: .5em;
    @include fontsize(xl);
    display: inline-block;
    cursor: pointer;
    z-index: 3;
    text-align: center;

    &::after {
        content: '✕';
        width: 1em; } }


.work-index__preview {
    flex: 0 0 percentage(1/3);
    overflow: hidden;
    cursor: pointer;
    transition: transition(transform, $factor: 1.25);
    transform: scale(1.02);

    @include breakpoint(all-plus) {
        flex: 0 0 120px; }


    &:last-child {
        margin-right: auto; }

    > div {
        @include page-margin-property(margin, $factor: 1/2); }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain; }

    &:hover {
        transform: scale(1.15); } }

.work-index__preview--empty {
    height: 1px;
    visibility: hidden;
    padding: 0 !important; }



$student-list-indent: 5em;
$student-list-indent-active: 15em;
$student-list-marker-offset: 5em;

$student-list-marker-padding-y: 5 * $one-line-height;

.student-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include font-scale(l, xl); }

.student-list__page-title {
    @extend %hide-visually; }

.student-list__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    left: $student-list-indent / 2;
    @include layout--students-side-by-side {
        left: $student-list-indent; }

    visibility: hidden;
    &.is-active {
        visibility: visible; } }

.student-list__list {
    min-height: 51vh;
    padding-right: 1em;
    @include layout--students-side-by-side {
        padding-right: 0; } }

.student-list__header,
.student-list__marker {
    padding-top: $student-list-marker-padding-y;
    padding-bottom: $student-list-marker-padding-y; }

.student-list__header {
    min-width: 30em; }

.student-list__header-name,
.student-list__header-link {
    display: inline-block;
    @include layout--students-side-by-side {
        width: $student-list-indent-active; } }

.student-list__header-name {
    span {
        @extend %underline-style--invisible; }
    &.is-active {
        span {
            @extend %underline-style; } } }

.student-list__header-link {
    cursor: pointer;
    opacity: .3;
    &:hover {
        opacity: 1;
        span {
            @extend %underline-style; } }

    display: none;
    @include layout--students-side-by-side {
        display: inline-block; } }

.student-list__scroll-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend %scrollable;
    overflow-x: hidden;

    // Hide scrollbars
    width: calc(100% + 50px);
    padding-right: 50px;
    &::-webkit-scrollbar {
        display: none; }

    .endless--fake .clones:last-child {
        padding-bottom: $student-list-marker-padding-y;
        padding-bottom: 70vh; } }


.student-list__marker {
    position: absolute;
    top: $student-list-marker-offset;
    top: 0;
    left: 0;
    width: 100%;
    &::before {
        display: inline-block;
        content: '→';
        transform: translateX(-2em) translateY(-.1em);
        .student-list__main:nth-child(2) & {
            @include layout--students-side-by-side {
                padding-left: $student-list-indent-active; } } } }

.student-list__photos {
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    height: 100%;
    z-index: 0;
    pointer-events: none;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 60%;
        object-fit: contain;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: none;
        &.is-active {
            opacity: 1; } } }

.student-list__student {
    .student-list__main:nth-child(2) & {
        @include layout--students-side-by-side {
            padding-left: $student-list-indent-active; } }

    &.is-active {
        .student-list__name {
            @extend %underline-style; } } }

.student-list__year {
    @extend %accent-typography;
    @include font-scale(m, m_l); }

%page-navigation {
    @include font-scale(s, l, m_l);
    background: palette('background');
    padding: 1em 1.5em;
    @include breakpoint(small) {
        padding: 1em 1em; }
    @include layout--pages-as-stack {
        background: transparent;
        padding: .35em 1em; } }

.student-list__navigation {
    position: relative;
    z-index: 2;
    @include layout--students-side-by-side {
        display: none; }

    @extend %page-navigation;

    span {
        display: inline-block;
        margin-right: 4em;
        cursor: pointer;
        &:hover,
        &.is-active {
            @extend %underline-style; } } }





// ! ---------- INFO


%info-title {
    @include font-scale(xxxl);
    margin-top: $one-line-height * 1;
    margin-bottom: $one-line-height / 2;
    @include breakpoint(small) {
        margin-bottom: $one-line-height;
        @include text-indent-padding; }
    @include layout--pages-standalone {
        .content-item:first-child & {
            margin-top: $one-line-height / 2; } } }

%info-container {
    width: 100%;
    max-width: 1340px;
    overflow: hidden;
    @include page-padding-property(padding-left);
    @include page-padding-property(padding-right);
    @include page-padding-property(padding-bottom, $factor: 2);
    @include layout--pages-as-stack {
        @include page-padding-property(padding-left, $factor: 2); } }

%info-container--wide {
    max-width: 1550px; }

.team, .info__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > header {
        flex: 0 0 100%; } }

.info__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend %scrollable;
    z-index: 1; }

.info__navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);
    .is-scrolled & {
        opacity: 1;
        pointer-events: auto; }

    @include breakpoint(small down) {
        display: none; }

    @include layout--pages-as-stack {
        top: 100%;
        left: 0;
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        width: 100vh;
        opacity: 1;
        pointer-events: auto; } }

.info__title,
.team__title {
    @extend %hide-visually; }

.info__slideshow {
    flex: 0 0 100%;
    max-width: 100%;
    @include page-padding-property(padding-top);
    @include page-padding-property(padding-bottom); }

.info__main {
    flex: 0 0 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @extend %info-container; }

.info__contact {
    flex: 0 1 auto;
    margin-left: auto;
    padding-bottom: 2 * $one-line-height;
    @include font-scale(m, m_l);
    @include breakpoint(large) {
        order: 2;
        flex-basis: 32%;
        @include page-padding-property(padding-left);
        margin-left: 0; }

    h1, h2, h3, h4, h5, h6 {
        @include font-scale(xl); } }

.info__about {
    flex: 0 1 100%;
    // max-width: 660px
    @include font-scale(l, xl);
    @include breakpoint(large) {
        order: 1;
        flex-basis: 66%; } }




// ! ---------- INFO AWARD LIST & PROJECT LIST


$info-column-gap: 2em;

.info__content,
.team__content, {
    @extend %info-container;
    @extend %info-container--wide; }

.info-award-list,
.info-project-list, {
    column-count: 1;
    column-gap: $info-column-gap;
    @include breakpoint(small) {
        column-count: 2; }
    @include breakpoint(large) {
        column-count: 3; } }

.info-course-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.info-course-list__semester {
    flex: 0 0 100%;
    overflow: hidden;
    padding-bottom: 2 * $one-line-height;
    @include breakpoint(small) {
        flex-basis: percentage(1/2);
        padding-right: $info-column-gap;
        padding-bottom: 2 * $one-line-height; }
    @include breakpoint(medium) {
        flex-basis: percentage(1/3); }
    @include breakpoint(large) {
        flex-basis: percentage(1/4); }
    @include breakpoint(xlarge) {
        flex-basis: percentage(1/5); } }

.info-info-block {}

.info-info-block__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.info-info-block__block {
    flex: 0 0 100%;
    @include breakpoint(medium) {
        flex-basis: percentage(1/2);
        &:nth-child(1), {
            padding-right: $info-column-gap/2; }
        &:nth-child(2), {
            padding-left: $info-column-gap/2; } }
    @include breakpoint(large) {
        flex-basis: percentage(1/3);
        &:nth-child(2), {
            padding-right: $info-column-gap/2; }
        &:nth-child(3), {
            padding-left: $info-column-gap/2; } } }

.info-award-list__award,
.info-project-list__project,
.info-course-list__header, {
    -webkit-column-break-inside: avoid;
    display: inline-block;
    width: 100%; }

.info-award-list__header,
.info-project-list__header,
.info-info-block__header,
.info-person-list__header,
.info-course-list__header, {
    @extend %info-title;
    column-span: all;

    a {
        @include fontsize(m);
        @extend %accent-typography;
        white-space: nowrap;
        &::before {
            content: '↘ ';
            content: '→';
            display: inline-block;
            padding-right: .25em;
            transform: rotate(45deg); } } }

.info-info-block__title,
.info-award-list__title,
.info-project-list__title, {
    display: inline;
    padding-right: .5em; }

.info-project-list__project,
.info-award-list__award,
.info-info-block__block {
    margin-bottom: $one-line-height * 3; }

.info-project-list__text,
.info-project-list__year,
.info-award-list__recipients, {
    @include text-indent-padding; }

.info-project-list__year {
    @extend %accent-typography;
    margin-bottom: $one-line-height; }

.info-project-list {
    a {
        &::before {
            content: '↘ ';
            content: '→';
            display: inline-block;
            padding-right: .25em;
            transform: rotate(45deg); } } }

.info-award-list__recipients {
    thead {
        display: none; }
    tbody {
        th {
            @include text-indent-padding;
            span {
                @extend %accent-typography; }
            &::after {
                content: '|';
                padding-left: .25em;
                padding-right: .25em; } }
        td {} } }

.info-info-block__block-title {

    @include font-scale(xl);
    span {
        display: inline-block;
        margin-left: 1em;
        @include font-scale(m);
        @extend %accent-typography; } }


.info-info-block__title {
 }    // display: inline

.info-info-block__text {
    margin-top: $one-line-height;
    blockquote {
        padding-left: 4em; } }

.js-content-toggle-container {
    h1, h2, h3, h4, h5, h6 {
        position: relative;
        cursor: pointer;
        padding-left: 1em;
        @include font-scale(m, m_l);
        &::before {
            position: absolute;
            left: 0;
            top: -.075em;
            content: '↓ '; }
        &.is-open::before {
            content: '→ ';
            left: -.6em; } }

    p, ul, ol, figure, img, table, blockquote, address {
        max-height: 0;
        overflow: hidden; }

    div.is-setup {
        padding-bottom: $one-line-height;
        p, ul, ol, figure, img, table, blockquote, address {
            max-height: none;
            overflow: auto;
            margin-bottom: $one-line-height; } } }



.js-content-toggle-all {
    position: relative;
    cursor: pointer;
    padding-left: 1em;
    &::before {
        position: absolute;
        left: 0;
        top: -.075em;
        content: '↓ '; }
    &.is-open::before {
        content: '→ ';
        left: -.6em; } }

.content-matrix--info {
    position: relative; }

.content-item {
    &::before {
        content: '_';
        display: block;
        width: 100%;
        height: 2px;
        visibility: hidden; } }

.info-person-list {}

.info-person-list__person {
    padding-bottom: $one-line-height;
    @include breakpoint(large) {
        max-width: 24em; } }

.info-person-list__name {
    @include font-scale(l);
    display: inline;

    &.has-info {
        cursor: pointer;
        &:hover,
        .is-toggled & {
            @extend %underline-style; } } }

.info-person-list__summary {
    @include font-scale(s);
    @extend %accent-typography;
    @include text-indent-padding;
    padding-top: .3em;
    @include breakpoint(medium) {
        max-width: 30em; } }

.info-person-list__info {

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 1.5em;

    opacity: 0;
    pointer-events: none;
    transition: transition(opacity);

    display: none;
    .is-toggled & {
        display: flex;
        opacity: 1;
        pointer-events: auto; }

    @include breakpoint(large) {
        display: flex;
        position: absolute;
        top: 0;
        left: 24em;
        right: 0;
        padding-top: 0;
        justify-content: center; } }


.info-person-list__bio,
.info-person-list__image {
    @include font-scale(s);
    flex: 0 0 100%;
    @include breakpoint(640) {
        flex: 0 0 50%; }
    @include breakpoint(large) {
        max-width: 26em; } }

.info-person-list__image, {
    flex: 0 0 50%;
    justify-self: flex-start; }

.info-person-list__bio {
    @extend %accent-typography;
    padding-right: 3em;
    order: 1;
    @include breakpoint(640) {
        order: -1; }
    @include breakpoint(large) {
        max-width: 28em;
        padding-left: 2em; }
    @include breakpoint(xlarge) {
        padding-left: 0; }

    p {
        margin-bottom: $one-line-height; }

    h1,h2,h3,h4,h5,h6 {
        margin-top: 2 * $one-line-height;
        margin-bottom: $one-line-height;
        &:first-child {
            margin-top: 0; }
        @include text-indent-padding(); }

    .language-switcher {
        @include text-indent-padding();
        margin-top: .35em; } }

.info-person-list__image {
    margin-bottom: $one-line-height * 2;

    img {
        display: block;
        width: 100%;
        height: auto; } }

.info-course-list__semester-title {
    @include font-scale(l);
    @extend %bold;
    min-height: 2 * $one-line-height;

    span {
        display: inline-block;
        @include breakpoint(small) {
            display: block; } } }

.info-course-list__courses {
    @include text-indent-padding();
    ul {
        @extend %accent-typography;
        @include font-scale(s);
        @extend %list--icon-dash; } }

.info-course-list__teacher {
    padding-top: $one-line-height;
    @include font-scale(m_l); }



// ! ---------- INFO SLIDESHOW



.full-width-slideshow {
    width: 100%;
    @include page-padding-property(padding-right); }

.full-width-slideshow__slide {
    width: 85vw;
    > div {
        @include page-padding-property(margin-left); }

    .non-ratio-container {
        display: none;
        height: 40vh;
        min-height: 240px;
        img {
            height: 100%;
            width: auto;
            max-width: none; } }

    @include breakpoint(small) {
        width: auto;
        .ratio-container {
            display: none; }
        .non-ratio-container {
            display: block; } } }

.slideshow__arrow-left,
.slideshow__arrow-right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 2;
    outline: none;
    @extend %no-tap-highlight;

    &.swiper-button-disabled {
        pointer-events: none; } }

.slideshow__arrow-left {
    left: 0;
    width: 33%; }

.slideshow__arrow-right {
    right: 0;
    width: 67%; }




// ! ---------- MULTI-LANGUAGE CONTENT


.language-content {

    html.no-js &[data-lang="en"] {
        display: none; }

    html.js & {
        display: none; }

    html.js[data-active-lang="de"] &[data-lang="de"] {
        display: block; }
    html.js[data-active-lang="en"] &[data-lang="en"] {
        display: block; } }

.language-switcher {
    padding-bottom: $one-line-height;
    @extend %basic-typography;
    @include font-scale(m);
    line-height: .9;

    > span {
        display: inline-block;
        padding: 0 1em; }

    button {
        @extend %neutral-button;
        position: relative;
        span {
            @extend %hide-visually; }
        &::after {
            content: attr(data-lang);
            text-transform: uppercase;
            font-size: .9em; }
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.9em;
            height: 1.9em;
            transform: translate3d(-50%, -46%, 2px);
            border-radius: 100%;
            border: 1px solid;
            z-index: -1;
            pointer-events: none;
            visibility: hidden; } }

    button:hover,
    html[data-active-lang="de"] & button[data-lang="de"],
    html[data-active-lang="en"] & button[data-lang="en"], {
        &::before {
            visibility: visible; } } }



.page-navigation {
    @extend %page-navigation;

    ul {
        list-style: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include layout--pages-as-stack {
            flex-direction: row-reverse; } }

    li {
        flex: 0 0 auto;
        cursor: pointer;

        &:hover, &.is-active {
            @extend %underline-style; } } }



.basic-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @extend %scrollable;
    z-index: 1;
    @include page-padding-property(padding); }

.basic-page__title {
    @extend %info-title; }

.basic-page__content {
    @include font-scale(m, l, xl);
    max-width: 40em; }
