// ! ---------- TYPOGRAPHY


%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base;

    @if $use-aktiv-font {
        font-family: $font-stack-default--aktiv;
        font-weight: 500; }

    @elseif $use-aktiv-font-on-live-server {
        &.is-not-localhost,
        .is-not-localhost & {
            font-family: $font-stack-default--aktiv;
            font-weight: 500; } } }

%accent-typography {
    font-family: $font-stack-accent;
    font-weight: normal; }


@mixin underline-style {
    text-decoration: none;
    background-image: linear-gradient(transparent 30%, palette('text') 30%);
    background-image: linear-gradient(transparent 30%, currentColor 30%);
    background-repeat: repeat-x;
    background-position: 0 89%;
    background-size: 1px .2rem;
    padding-bottom: .2rem;

    .ios & {
        background-size: 1px .16rem;
        background-position: 0 86%; }

    // Disable for IEdge

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: none;
        text-decoration: underline; } }

@mixin underline-style--invisible {
    @include underline-style;
    background-image: none;
    text-decoration: none; }

%underline-style {
    @include underline-style; }

%underline-style--invisible {
    @include underline-style--invisible; }




// ! ---------- TYPOGRAPHY

@mixin font-scale($base, $tablet: null, $desktop: null) {
    @include fontsize($base);
    @if $tablet {
        @include breakpoint(medium) {
            @include fontsize($tablet); } }
    @if $desktop {
        @include breakpoint(large) {
            @include fontsize($desktop); } } }
